(function () {
    "use strict";

    angular.module("angular.emlpayments")
        .factory("api", cardspotApi);

    cardspotApi.$inject = ["$log", "$http", "$translate", "store", "CARDSPOT_SERVICE_FOLDER", "$q", "$timeout", "$rootScope", "REGION_TYPES"];

    function cardspotApi($log, $http, $translate, store, CARDSPOT_SERVICE_FOLDER, $q, $timeout, $rootScope, REGION_TYPES) {

        return {
            configuration: {
                getMerchantGroups,
                getMerchantGroupLocations,
                addMerchantGroupLocation,
                getMerchantGroupSettings,
                setMerchantGroupSettings,
                getDisplayFeeBasedSignageMessageFlag
            },
            cards: {
                lookupCardByPrintText,
                lookupCardByExternalId,
                lookupCardBySwipe,
                lookupCardByClientTrackingId,
                lookupCardByCarrierNumber,
                reloadCard
            },
            activation: {
                getFees: getFees,
                getSalesChannels: getSalesChannels,
                activate: activate,
                activateCardPerCustomer: activateCardPerCustomer,
                sendEmailReceipt: sendEmailReceipt,
                sendEmailInvoice: sendEmailInvoice,
                getEmailReceipt: getEmailReceipt,
                getPaymentTypes: getPaymentTypes,
                getInvoice: getInvoice,
                getIndividualCardReceipts: getIndividualCardReceipts
            },
            preActivation: {
                preActivateCardByPrintText: preActivateCardByPrintText,
                preActivateCardBySwipe: preActivateCardBySwipe,
                preActivateGroup: preActivateGroup,
                getPreActivationMerchantGroupProgramInformation: getPreActivationMerchantGroupProgramInformation,
                preActivateCardByExternalId: preActivateCardByExternalId
            },
            location: {
                getLocations,
                updateLocation
            },
            lookupValues: {
                getLockCardReasons: getLockCardReasons,
                getCountries: getCountries,
                getRegions: getRegions,
                getAccountExpirationExtensionReasons,
                getCountryCode: getCountryCode,
                getKYCDocumentTypes: getKYCDocumentTypes,
                getActiveMerchantGroupPrograms: getActiveMerchantGroupPrograms,
                getRiskAssessmentLevels: getRiskAssessmentLevels
            },
            cardStatus: {
                lockCard: lockCard,
                unlockCard: unlockCard
            },
            reload : {
                reloadCard: reloadCard
            },
            transfer: {
                transferCard: transferCard
            },
            transaction: {
                getTransaction,
                voidActivationTransaction,
                lockActivationTransaction,
                unlockActivationTransaction,
                getTransactionsByCampaign,
                getCampaigns
            },
            customer: {
                searchCustomers: searchCustomers,
                searchCustomerCards: searchCustomerCards,
                updateCustomer: updateCustomer
            },
            sundries: {
                getSundryItemTypes: getSundryItemTypes,
                sundryPurchase: sundryPurchase,
                getSundryCurrencies: getSundryCurrencies,
                getAllSundryItemTypes: getAllSundryItemTypes,
                getPagedSundryItemTypes: getPagedSundryItemTypes,
                addMerchantGroupSundry: addMerchantGroupSundry,
                updateMerchantGroupSundry: updateMerchantGroupSundry
            },
            receipts: {
                getReceiptItems,
                setReceiptItems,
                getReceiptCultures
            },
            fulfillment: {
                getFulfillmentQueue,
                getFulfillmentQueueCount,
                beginFulfillment,
                overrideBeginFulfillment,
                getFulfillmentOrder,
                preActivateByPrintText,
                preActivateBySwipe,
                cancelFulfillment,
                cancelOrder,
                fulfillOrder,
                getFulfillmentHistory,
                updateTrackingInformation,
                preActivateCardGroup,
                getShippingMethods,
                getOrderItemCardAmountCardholdersExport,
                fulfillDigitalOrder
            },
            orderCards: {
                getMerchantGroupContact,
                saveMerchantGroupContact,
                getCardDesigns,
                saveOrderRequest
            },
            campaign: {
                getAllMerchantGroupCampaigns,
                getMerchantGroupCampaigns,
                addMerchantGroupCampaign,
                updateMerchantGroupCampaign
            },
            diagnostic: {
                getClientIPAddress,
                logIntegratedPayment
            },
            inventory: {
                getCardOrderDesigns,
                getTodaysInventoryCount,
                getInventoryCardByPrintText,
                getInventoryCardBySwipe,
                getInventoryCardByExternalId,
                getInventoryCardGroup,
                retireInventoryCardOrder,
                insertInventoryCardOrderReconciliation,
                deleteInventoryCardOrderReconciliation,
                getCardOrderReconciliationStatusReasons
            },
            invoice: {
                getCompanyInvoices
            },
            expiration: {
                extendAccountExpiration,
                calculateAccountExpiration
            },
            code: {
                redeemStart,
                redeemComplete,
                redeemCancel
            },
            limits: {
                getProgramHvtLimits
            },
            hvt: {
                submitHvtRequest
            },
            order: {
                getCardFrontOrders,
                getCardFrontOrderCardDetails,
                searchCardFrontOrderCardDetails,
                getCardFrontOrderDetails,
                saveCardFrontOrderDetails,
                saveCardFrontCardDetails,
                deleteCardFrontCardholder,
                insertCardFrontCardholder,
                getCardFrontOrderInvoice
            }
        };

        function prefixUrl(url) {
            return CARDSPOT_SERVICE_FOLDER + url;
        }

        function getMerchantGroups() {
            return $http.get(prefixUrl("/merchantgroups"), {});
        }

        function getMerchantGroupLocations(merchantGroupUniqueTag) {
            var apiRoute = "/locations/merchantgroup/" + merchantGroupUniqueTag;
            return $http.get(prefixUrl(apiRoute));
        }

        function addMerchantGroupLocation(merchantGroupUniqueTag, locationName) {
            var apiRoute = "/locations/merchantgroup/" + merchantGroupUniqueTag;
            return $http.post(prefixUrl(apiRoute), { location: locationName });
        }

        function getMerchantGroupSettings(merchantGroupUniqueTag) {
            if (!$rootScope.isLoginPage()) {
                var apiRoute = "/settings/merchantgroup/" + merchantGroupUniqueTag;
                return $http.get(prefixUrl(apiRoute));
            }
        }

        function setMerchantGroupSettings(
            merchantGroupUniqueTag,
            supportsCustomerAtCardLevel,
            supportsSearchByClientTrackingId,
            defaultCampaignSelection,
            manageCampaigns,
            receiptGroupingThreshold,
            manageLocations,
            supportSearchByCarrierNumber,
            corporateInvoiceEnabled,
            individualCardReceiptsEnabled,
            clickAndCollectEnabled,
            hvtRequestEnabled
        ) {
            var apiRoute = `/settings/merchantgroup/${merchantGroupUniqueTag}`;
            return $http.put(prefixUrl(apiRoute),
                {
                    supportsCustomerAtCardLevel,
                    supportsSearchByClientTrackingId,
                    defaultCampaignSelection,
                    manageCampaigns,
                    receiptGroupingThreshold,
                    manageLocations,
                    supportSearchByCarrierNumber,
                    corporateInvoiceEnabled,
                    individualCardReceiptsEnabled,
                    clickAndCollectEnabled,
                    hvtRequestEnabled
                });
        }

        function getDisplayFeeBasedSignageMessageFlag(merchantGroupUniqueTag) {
            var apiRoute = `/merchantgroup/${merchantGroupUniqueTag}/program/DisplayFeeBasedSignageMessage`;
            return $http.get(prefixUrl(apiRoute));
        }

        function lookupCardByPrintText(printText) {
            var currentCulture = $translate.currentLanguage().key.toLowerCase();
            return $http.post(prefixUrl(`/lookup-card/manual-entry?culture=${currentCulture}`), { printText: printText });
        }

        function lookupCardByExternalId(externalId) {
            var currentCulture = $translate.currentLanguage().key.toLowerCase();
            return $http.post(prefixUrl(`/lookup-card/external-id?culture=${currentCulture}`), { externalId: externalId });
        }

        function lookupCardBySwipe(track1Data, track2Data) {
            var currentCulture = $translate.currentLanguage().key.toLowerCase();
            return $http.post(prefixUrl(`/lookup-card/swipe?culture=${currentCulture}`), { track1Data: track1Data, track2Data: track2Data });
        }

        function lookupCardByClientTrackingId(merchantGroupUniqueTag, clientTrackingId) {
            return $http.post(prefixUrl(`/lookup-card/${merchantGroupUniqueTag}/client-tracking-id`), { clientTrackingId });
        }

        function lookupCardByCarrierNumber(merchantGroupUniqueTag, carrierNumber) {
            return $http.post(prefixUrl(`/lookup-card/${merchantGroupUniqueTag}/carrier-number`), { carrierNumber });
        }

        function reloadCard(externalId, reloadRequest) {
            return $http.post(prefixUrl(`/card/${externalId}/reload`), reloadRequest);
        }

        function preActivateCardByPrintText(printText, merchantGroupUniqueTag) {
            return $http.post(prefixUrl("/preactivate/entry"), { printText: printText, merchantGroupUniqueTag: merchantGroupUniqueTag });
        }

        function preActivateCardByExternalId(externalId, merchantGroupUniqueTag) {
            return $http.post(prefixUrl("/preactivate/external-id"), { externalId: externalId, merchantGroupUniqueTag: merchantGroupUniqueTag });
        }

        function preActivateCardBySwipe(primaryAccountNumber, merchantGroupUniqueTag) {
            return $http.post(prefixUrl("/preactivate/swipe"), {
                primaryAccountNumber: primaryAccountNumber,
                merchantGroupUniqueTag: merchantGroupUniqueTag
            });
        }

        function preActivateGroup(firstCardNumber, lastCardNumber, merchantGroupUniqueTag) {
            var apiRoute = "/preactivate/group/merchantgroup/" + merchantGroupUniqueTag;
            return $http.post(prefixUrl(apiRoute), {
                firstCardNumber: firstCardNumber,
                lastCardNumber: lastCardNumber
            });
        }

        function getPreActivationMerchantGroupProgramInformation(merchantGroupUniqueTag, programUniqueTag) {
            var currentCulture = $translate.currentLanguage().key.toLowerCase();
            var apiRoute = "/preactivate/merchantgroup/" + merchantGroupUniqueTag + "/program/" + programUniqueTag + "?culture=" + currentCulture;
            return $http.get(prefixUrl(apiRoute));
        }

        function getLockCardReasons() {
            return $http.get(prefixUrl("/status/lock-reasons"));
        }

        function lockCard(merchantGroupUniqueTag, cardNumber, location, reason, note) {
            var apiRoute = "/card/status/lock/merchantgroup/" + merchantGroupUniqueTag;
            return $http.post(prefixUrl(apiRoute), { location: location, cardNumber: cardNumber, lockCardReason: reason, note: note });
        }

        function unlockCard(merchantGroupUniqueTag, cardNumber, location, note) {
            var apiRoute = "/card/status/unlock/merchantgroup/" + merchantGroupUniqueTag;
            return $http.post(prefixUrl(apiRoute), { location: location, cardNumber: cardNumber, note: note });
        }

        function getFees(merchantGroupUniqueTag, programUniqueTag, cards) {
            var apiRoute = "/activate/fees/merchantgroup/" + merchantGroupUniqueTag + "/program/" + programUniqueTag;
            return $http.post(prefixUrl(apiRoute), { cards: cards });
        }

        function getSalesChannels() {
            var currentCulture = $translate.currentLanguage().key.toLowerCase();
            return $http.post(prefixUrl("/activate/sales-channels?culture=" + currentCulture));
        }

        function activate(merchantGroupUniqueTag, programUniqueTag, activationArgs) {
            var apiRoute = "/activate/merchantgroup/" + merchantGroupUniqueTag + "/program/" + programUniqueTag;
            return $http.post(prefixUrl(apiRoute), activationArgs);
        }

        function activateCardPerCustomer(merchantGroupUniqueTag, programUniqueTag, activationArgs) {
            var apiRoute = "/activate/card-per-customer/merchantgroup/" + merchantGroupUniqueTag + "/program/" + programUniqueTag;
            return $http.post(prefixUrl(apiRoute), activationArgs);
        }


        function sundryPurchase(merchantGroupUniqueTag, sundrySaleArgs) {
            var apiRoute = "/sundry-sale/merchantgroup/" + merchantGroupUniqueTag;
            return $http.post(prefixUrl(apiRoute), sundrySaleArgs);
        }

        function sendEmailReceipt(transactionId, email, cultureName) {
            var apiRoute = "/receipt/transaction/" + transactionId + "/culture/" + cultureName;
            return $http.post(prefixUrl(apiRoute), { destinationEmailAddress: email });
        }

        function sendEmailInvoice(transactionId, email, cultureName) {
            var apiRoute = "/invoice/transaction/" + transactionId + "/culture/" + cultureName;
            return $http.post(prefixUrl(apiRoute), { destinationEmailAddress: email });
        }

        function getCountries() {
            return $http.get(prefixUrl("/activate/countries"));
        }

        function getCountryCode(countryDescription) {
            var apiRoute = "/activate/country-code/" + countryDescription;
            return $http.get(prefixUrl(apiRoute));
        }

        function getKYCDocumentTypes() {
            var apiRoute = "/activate/kyc/document-types";
            return $http.get(prefixUrl(apiRoute));
        }

        function getRegions(countryKey) {
            return $http.post(prefixUrl("/activate/regions"), { countryKey: countryKey });
        }

        function transferCard(merchantGroupUniqueTag, location, transferModel) {
            var apiRoute = "/transfer/merchantgroup/" + merchantGroupUniqueTag;
            return $http.post(prefixUrl(apiRoute), {
                location: location,
                fromCardNumber: transferModel.fromCardNumber,
                toCardNumber: transferModel.toCardNumber,
                paymentType: transferModel.paymentType,
                transferFeeAmount: transferModel.transferFeeAmount,
                note: transferModel.note,
                receiptDestinationEmailAddress: transferModel.receiptDestinationEmailAddress,
                activationLock: transferModel.activationLock
            });
        }

        function getEmailReceipt(transactionId, cultureName, isReprint = false, cardFrontOrderId = null) {
            let baseRoute = `/receipt/transaction/${transactionId}/culture/${cultureName}`;
            let apiRoute = isReprint && cardFrontOrderId ? baseRoute + `?isReprint=${isReprint}&cardFrontOrderId=${cardFrontOrderId}`
                : isReprint && !cardFrontOrderId ? baseRoute + `?isReprint=${isReprint}`
                    : !isReprint && cardFrontOrderId ? baseRoute + `?cardFrontOrderId=${cardFrontOrderId}` : baseRoute;
            return $http.get(prefixUrl(apiRoute));
        }

        function getIndividualCardReceipts(transactionId, cultureName, totalCards, isReprint = false) {
            let baseRoute = `/receipt/transaction/${transactionId}/culture/${cultureName}/cardCount/${totalCards}`;
            let apiRoute = baseRoute + `?isReprint=${isReprint}`;
            return $http.get(prefixUrl(apiRoute));
        }

        function getTransaction(transactionId) {
            var currentCulture = $translate.currentLanguage().key.toLowerCase();
            return $http.get(prefixUrl("/lookup-sale/" + transactionId + "?culture=" + currentCulture));
        }

        function getTransactionsByCampaign(merchantGroupUniqueTag, campaign) {
            var apiRoute = "/lookup-sale/campaign/merchantGroup/" + merchantGroupUniqueTag;
            return $http.post(prefixUrl(apiRoute), { campaignDescription: campaign });
        }

        function getCampaigns(merchantGroupUniqueTag) {
            var apiRoute = `/transaction/campaigns/merchantgroup/${merchantGroupUniqueTag}`;
            return $http.get(prefixUrl(apiRoute));
        }

        function voidActivationTransaction(merchantGroupUniqueTag, transactionId, location, note) {
            var apiRoute = "/transaction/status/void/merchantgroup/" + merchantGroupUniqueTag;
            return $http.post(prefixUrl(apiRoute), { locationName: location, systemTransactionId: transactionId, note: note });
        }

        function lockActivationTransaction(merchantGroupUniqueTag, transactionId, location) {
            var apiRoute = "/transaction/status/lock/merchantgroup/" + merchantGroupUniqueTag;
            return $http.post(prefixUrl(apiRoute), { locationName: location, systemTransactionId: transactionId });
        }

        function unlockActivationTransaction(merchantGroupUniqueTag, transactionId, location) {
            var apiRoute = "/transaction/status/unlock/merchantgroup/" + merchantGroupUniqueTag;
            return $http.post(prefixUrl(apiRoute), { locationName: location, systemTransactionId: transactionId });
        }

        function searchCustomers(
            merchantGroupUniqueTag, 
            isOrganization, 
            name, 
            firstName, 
            lastName, 
            phoneNumber, 
            identifier, 
            email,
            kybAssessmentLevel,
            companyRegistrationNumber,
            assessmentFromDate,
            assessmentToDate) {
            var apiRoute = "/lookup-customer/merchantgroup/" + merchantGroupUniqueTag;
            return $http.post(prefixUrl(apiRoute), {
                isOrganization: isOrganization,
                name: name,
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber,
                identifier: identifier,
                email: email,
                kybAssessmentLevel: kybAssessmentLevel,
                companyRegistrationNumber: companyRegistrationNumber,
                assessmentFromDate: assessmentFromDate,
                assessmentToDate: assessmentToDate
            });
        }

        function searchCustomerCards(isOrganization, customerId, pageNumber, pageSize) {
            var apiRoute = "/lookup-customer/cards";
            return $http.post(prefixUrl(apiRoute), { 
                isOrganization: isOrganization, 
                customerId: customerId, 
                pageNumber: pageNumber, 
                pageSize: pageSize });
        }

        function updateCustomer(merchantGroupUniqueTag, updateModel) {
            var apiRoute = "/lookup-customer/merchantgroup/" + merchantGroupUniqueTag;
            return $http.put(prefixUrl(apiRoute),
                {
                    isOrganization: updateModel.isOrganization,
                    customerId: updateModel.customerId,
                    name: updateModel.name,
                    firstName: updateModel.firstName,
                    middleName: updateModel.middleName,
                    lastName: updateModel.lastName,
                    phoneNumber: updateModel.phoneNumber,
                    phoneNumberExtension: updateModel.phoneNumberExtension,
                    faxNumber: updateModel.faxNumber,
                    faxNumberExtension: updateModel.faxNumberExtension,
                    emailAddress: updateModel.emailAddress,
                    country: updateModel.country,
                    region: updateModel.region,
                    postalCode: updateModel.postalCode,
                    city: updateModel.city,
                    address1: updateModel.address1,
                    address2: updateModel.address2,
                    identifier: updateModel.identifier,
                    vat: updateModel.vat,
                    companyRegistrationNumber: updateModel.companyRegistrationNumber,
                    updateKybAssessmentModel: {
                        assessmentLevel: updateModel.assessmentLevel,
                        isApproved: updateModel.isKybAccepted,
                        assessmentDate: updateModel.assessmentDate,
                        runId: updateModel.kybRunId,
                        kybResponseId: updateModel.kybResponseId
                    }
                });
        }

        function getSundryItemTypes(merchantGroupUniqueTag) {
            var apiRoute = "/sundry-item-types/" + merchantGroupUniqueTag;
            return $http.get(prefixUrl(apiRoute));
        }

        function getSundryCurrencies(merchantGroupUniqueTag) {
            var apiRoute = "/merchantgroup/" + merchantGroupUniqueTag + "/sundry/currencies";
            return $http.get(prefixUrl(apiRoute));
        }

        function getAllSundryItemTypes(merchantGroupUniqueTag) {
            var apiRoute = "/merchantgroup/" + merchantGroupUniqueTag + "/sundry-item-types";
            return $http.get(prefixUrl(apiRoute));
        }

        function getPagedSundryItemTypes(merchantGroupUniqueTag, pageNumber, pageSize, sortAscending, isActive) {
            var apiRoute = "/merchantgroup/" + merchantGroupUniqueTag + "/sundry-item-types?";
            apiRoute += `pageNumber=${pageNumber}&pageSize=${pageSize}`;
            apiRoute += `&sortAscending=${sortAscending}`;
            apiRoute += `&isActive=${isActive}`;
            return $http.get(prefixUrl(apiRoute));
        }

        function addMerchantGroupSundry(merchantGroupUniqueTag, model) {
            var apiRoute = "/merchantgroup/" + merchantGroupUniqueTag + "/sundry-item-types";
            return $http.post(prefixUrl(apiRoute), model);
        }

        function updateMerchantGroupSundry(sundryItemTypeId, model) {
            var apiRoute = "/sundry-item-type/" + sundryItemTypeId;
            return $http.put(prefixUrl(apiRoute), model);
        }

        function getPaymentTypes(merchantGroupUniqueTag) {
            var currentCulture = $translate.currentLanguage().key.toLowerCase();
            var apiRoute = "/payment-types/merchantgroup/" + merchantGroupUniqueTag + "?culture=" + currentCulture;
            return $http.get(prefixUrl(apiRoute));
        }

        function getInvoice(transactionId) {
            var currentCulture = $translate.currentLanguage().key.toLowerCase();
            return $http.get(prefixUrl("/activate/invoice/" + transactionId + "?cultureName=" + currentCulture), {
                responseType: "arraybuffer"
            });
        }

        function getReceiptItems(merchantGroupUniqueTag, cultureName) {
            var apiRoute = "/receipt-items/merchantgroup/" + merchantGroupUniqueTag + "/culture/" + cultureName;
            return $http.get(prefixUrl(apiRoute));
        }

        function setReceiptItems(merchantGroupUniqueTag, cultureName, receiptItems, termsAndConditions) {
            var apiRoute = "/receipt-items/merchantgroup/" + merchantGroupUniqueTag + "/culture/" + cultureName;
            return $http.post(prefixUrl(apiRoute), { receiptItems: receiptItems, receiptTermsAndConditions: termsAndConditions });
        }

        function getReceiptCultures(merchantGroupUniqueTag) {
            var apiRoute = "/receipt/cultures/merchantgroup/" + merchantGroupUniqueTag;
            return $http.get(prefixUrl(apiRoute));
        }

        function getFulfillmentQueue(
            merchantGroupUniqueTag,
            searchValue,
            orderDateBegin,
            orderDateEnd,
            isCorporate = false
        ) {
            var apiRoute = "/fulfillment/queue/merchantgroup/" + merchantGroupUniqueTag + "?";
            var params = [];

            if (searchValue) {
                params.push(`searchValue=${searchValue}`);
            }
            if (orderDateBegin) {
                params.push(`orderDateBegin=${orderDateBegin}`);
            }
            if (orderDateEnd) {
                params.push(`orderDateEnd=${orderDateEnd}`);
            }
            params.push(`isCorporate=${isCorporate}`);

            for (var i = 0; i < params.length; i++) {
                apiRoute += params[i];
                if (i < params.length - 1) { apiRoute += "&"; }
            }

            return $http.get(prefixUrl(apiRoute));
        }

        function getFulfillmentQueueCount(merchantGroupUniqueTag) {
            var apiRoute = "/fulfillment/count/merchantgroup/" + merchantGroupUniqueTag;
            return $http.get(prefixUrl(apiRoute));
        }

        function beginFulfillment(orderId) {
            var apiRoute = "/fulfillment/begin/order/" + orderId;
            return $http.post(prefixUrl(apiRoute));
        }

        function overrideBeginFulfillment(orderId) {
            var apiRoute = "/fulfillment/begin/order/" + orderId + "?modifyWhileInProgress=true";
            return $http.post(prefixUrl(apiRoute));
        }

        function getFulfillmentOrder(orderId, isCorporate) {
            var apiRoute = "/fulfillment/order/" + orderId + "?";
            apiRoute += `isCorporate=${isCorporate}`;
            return $http.get(prefixUrl(apiRoute));
        }

        function preActivateByPrintText(orderId, printText) {
            var apiRoute = "/fulfillment/order/" + orderId + "/preactivate/entry";
            return $http.post(prefixUrl(apiRoute), { printText: printText });
        }

        function preActivateBySwipe(orderId, primaryAccountNumber) {
            var apiRoute = "/fulfillment/order/" + orderId + "/preactivate/swipe";
            return $http.post(prefixUrl(apiRoute), { primaryAccountNumber: primaryAccountNumber });
        }

        function cancelFulfillment(orderId) {
            var apiRoute = "/fulfillment/cancel/" + orderId;
            return $http.post(prefixUrl(apiRoute));
        }

        function cancelOrder(orderId) {
            var apiRoute = "/fulfillment/cancel/order/" + orderId;
            return $http.put(prefixUrl(apiRoute));
        }

        function fulfillOrder(orderId, location, orderItems, lockTransactionOnActivation) {
            var apiRoute = "/fulfillment/order/" + orderId;
            return $http.post(prefixUrl(apiRoute), { location: location, orderItems: orderItems, lockTransactionOnActivation: lockTransactionOnActivation });
        }

        function updateTrackingInformation(orderId, orderItemId, trackingInformation) {
            var apiRoute = "/fulfillment/order/" + orderId;
            return $http.put(prefixUrl(apiRoute), { orderItemId: orderItemId, trackingInformation: trackingInformation });
        }

        function getFulfillmentHistory(
            merchantGroupUniqueTag,
            pageNumber,
            pageSize,
            searchValue,
            orderDateBegin,
            orderDateEnd,
            columnSort,
            sortAscending,
            isCorporate) {
            var apiRoute = "/fulfillment/history/merchantgroup/" + merchantGroupUniqueTag + "?";
            apiRoute += `pageNumber=${pageNumber}&pageSize=${pageSize}`;
            apiRoute += searchValue ? `&searchValue=${searchValue}` : "";
            apiRoute += orderDateBegin ? `&orderDateBegin=${orderDateBegin}` : "";
            apiRoute += orderDateEnd ? `&orderDateEnd=${orderDateEnd}` : "";
            apiRoute += `&columnSort=${columnSort}&sortAscending=${sortAscending}`;
            apiRoute += `&isCorporate=${isCorporate}`;
            return $http.get(prefixUrl(apiRoute));
        }

        function getOrderItemCardAmountCardholdersExport(orderId) {
            var apiRoute = `/fulfillment/order/${orderId}/order-item/card-amount/cardholders`;
            return $http.get(prefixUrl(apiRoute));
        }

        function fulfillDigitalOrder(orderId, location, orderItems) {
            var apiRoute = `/fulfillment/digital/order/${orderId}`;
            return $http.post(prefixUrl(apiRoute), { location: location, orderItems: orderItems });
        }

        function preActivateCardGroup(orderId, firstCardNumber, lastCardNumber) {
            var apiRoute = "/fulfillment/order/" + orderId + "/preactivate/group";
            return $http.post(prefixUrl(apiRoute), {
                firstCardNumber: firstCardNumber,
                lastCardNumber: lastCardNumber
            });
        }

        function getShippingMethods(merchantGroupUniqueTag) {
            var apiRoute = "/fulfillment/shippingmethods/merchantgroup/" + merchantGroupUniqueTag;
            return $http.get(prefixUrl(apiRoute));
        }

        function getMerchantGroupContact(merchantGroupUniqueTag) {
            var apiRoute = `/inventory/primarycontact/merchantgroup/${merchantGroupUniqueTag}`;
            return $http.get(prefixUrl(apiRoute));
        }

        function saveMerchantGroupContact(merchantGroupUniqueTag, model) {
            var apiRoute = `/inventory/primarycontact/merchantgroup/${merchantGroupUniqueTag}`;
            return $http.put(prefixUrl(apiRoute),
                {
                    firstName: model.firstName,
                    lastName: model.lastName,
                    title: model.title,
                    emailAddress: model.emailAddress,
                    phone: model.phone,
                    phoneExt: model.phoneExt,
                    propertyUrl: model.propertyUrl,
                    address1: model.address1,
                    address2: model.address2,
                    city: model.city,
                    stateOrProvince: model.stateOrProvince,
                    postalCode: model.postalCode,
                    country: model.country
                });
        }

        function getCardDesigns(merchantGroupUniqueTag) {
            var apiRoute = `/inventory/carddesigns/merchantgroup/${merchantGroupUniqueTag}`;
            return $http.get(prefixUrl(apiRoute));
        }

        function saveOrderRequest(merchantGroupUniqueTag, model) {
            var apiRoute = `/inventory/request/merchantgroup/${merchantGroupUniqueTag}`;
            return $http.post(prefixUrl(apiRoute),
                model);
        }

        function getAllMerchantGroupCampaigns(merchantGroupUniqueTag) {
            var apiRoute = `/campaigns/merchantgroup/${merchantGroupUniqueTag}`;
            return $http.get(prefixUrl(apiRoute));
        }

        function getMerchantGroupCampaigns(merchantGroupUniqueTag, pageNumber, pageSize, sortAscending, isActive) {
            var apiRoute = "/merchantgroup/" + merchantGroupUniqueTag + "/campaigns?";
            apiRoute += `pageNumber=${pageNumber}&pageSize=${pageSize}`;
            apiRoute += `&sortAscending=${sortAscending}`;
            apiRoute += `&isActive=${isActive}`;
            return $http.get(prefixUrl(apiRoute));
        }

        function addMerchantGroupCampaign(merchantGroupUniqueTag, description) {
            var apiRoute = "/add/campaigns/merchantgroup/" + merchantGroupUniqueTag + "?";
            apiRoute += `campaignDescription=${description}`;
            return $http.post(prefixUrl(apiRoute));
        }

        function updateMerchantGroupCampaign(model) {
            var apiRoute = "/merchantgroup/campaign/update";
            return $http.put(prefixUrl(apiRoute), model);
        }

        function getLocations(merchantGroupUniqueTag, pageNumber, pageSize, sortAscending, isActive) {
            var apiRoute = "/merchantgroup/" + merchantGroupUniqueTag + "/locations?";
            apiRoute += `pageNumber=${pageNumber}&pageSize=${pageSize}`;
            apiRoute += `&sortAscending=${sortAscending}`;
            apiRoute += `&isActive=${isActive}`;
            return $http.get(prefixUrl(apiRoute));
        }

        function updateLocation(model) {
            var apiRoute = "/merchantgroup/location/update";
            return $http.put(prefixUrl(apiRoute), model);
        }

        function getClientIPAddress() {
            var apiRoute = "/diagnostic/client-ip";
            return $http.get(prefixUrl(apiRoute));
        }

        function logIntegratedPayment(traceModel) {
            var apiRoute = "/diagnostic/log";
            return $http.post(prefixUrl(apiRoute), traceModel);
        }

        function getCardOrderDesigns(merchantGroupUniqueTag, includeLockedOrders = false) {
            var apiRoute = `/inventory/cardorder/carddesigns/merchantgroup/${merchantGroupUniqueTag}`;
            return $http.get(prefixUrl(apiRoute));
        }

        function getTodaysInventoryCount(orderId, merchantGroupUniqueTag, isUnusable = false) {
            var apiRoute = `/inventory/count/order/${orderId}/merchantgroup/${merchantGroupUniqueTag}` + "?";
            apiRoute += `isUnusable=${isUnusable}`;
            return $http.get(prefixUrl(apiRoute));
        }

        function retireInventoryCardOrder(cardOrderId) {
            var apiRoute = `/inventory/retired-card-order/${cardOrderId}`;
            return $http.post(prefixUrl(apiRoute));
        }

        function insertInventoryCardOrderReconciliation(cardOrderId, merchantGroupUniqueTag, model) {
            var apiRoute = `/inventory/card-order/${cardOrderId}/merchantgroup/${merchantGroupUniqueTag}`;
            return $http.post(prefixUrl(apiRoute), model);
        }

        function deleteInventoryCardOrderReconciliation(cardOrderReconciliationId, firstCardNumber, lastCardNumber) {
            var apiRoute = `/inventory/card-order-reconciliation/${cardOrderReconciliationId}`;
            return $http.post(prefixUrl(apiRoute), {
                firstCardNumber: firstCardNumber,
                lastCardNumber: lastCardNumber
            });
        }

        function getInventoryCardByPrintText(printText, cardOrderId) {
            var apiRoute = "/inventory/entry";
            return $http.post(prefixUrl(apiRoute), {
                printText: printText,
                cardOrderId: cardOrderId
            });
        }

        function getInventoryCardBySwipe(primaryAccountNumber, cardOrderId) {
            var apiRoute = "/inventory/swipe";
            return $http.post(prefixUrl(apiRoute), {
                primaryAccountNumber: primaryAccountNumber,
                cardOrderId: cardOrderId
            });
        }

        function getInventoryCardByExternalId(externalId, cardOrderId) {
            var apiRoute = "/inventory/external-id";
            return $http.post(prefixUrl(apiRoute), {
                externalId: externalId,
                cardOrderId: cardOrderId
            });
        }

        function getInventoryCardGroup(firstCardNumber, lastCardNumber) {
            var apiRoute = "/inventory/card/group";
            return $http.post(prefixUrl(apiRoute), {
                firstCardNumber: firstCardNumber,
                lastCardNumber: lastCardNumber
            });
        }
        function getCardOrderReconciliationStatusReasons() {
            var apiRoute = "/inventory/card-order-reconciliation-status-reasons";
            return $http.get(prefixUrl(apiRoute));
        }

        function ensureCultureIsSet(culture) {
            return culture ? culture : "en-US";
        }

        function getCompanyInvoices(merchantGroupUniqueTag, pageNumber, pageSize, sortAscending, companyName, invoiceNumber, toDate, fromDate) {
            var apiRoute = "/merchantgroup/" + merchantGroupUniqueTag + "/invoices?";
            apiRoute += `pageNumber=${pageNumber}&pageSize=${pageSize}`;
            apiRoute += `&sortAscending=${sortAscending}`;
            apiRoute += companyName ? `&companyName=${companyName}` : "";
            apiRoute += invoiceNumber ? `&invoiceNumber=${invoiceNumber}` : "";
            apiRoute += toDate ? `&toDate=${toDate}` : "";
            apiRoute += fromDate ? `&fromDate=${fromDate}` : "";
            return $http.get(prefixUrl(apiRoute));
        }

        function extendAccountExpiration(cardNumber, newAccountExpirationDate, extensionReason) {
            var apiRoute = "/expiration/account/extend";
            return $http.post(prefixUrl(apiRoute), { cardNumber: cardNumber, newAccountExpirationDate: newAccountExpirationDate, extensionReason: extensionReason });
        }

        function getAccountExpirationExtensionReasons() {
            return $http.get(prefixUrl("/expiration/extensionreasons"));
        }

        function calculateAccountExpiration(cardNumber) {
            var apiRoute = "/expiration/account/calculate";
            return $http.post(prefixUrl(apiRoute), { cardNumber: cardNumber });
        }

        //#region Code
        function redeemStart(code, program, merchantGroup, username) {
            var apiRoute = "/code/merchantgroup/" + merchantGroup + "/program/" + program + "/redeem/start";
            return $http.post(prefixUrl(apiRoute), {
                code: code,
                username: username
            })
        }

        function redeemComplete(code, program, merchantGroup, sessionId, externalId, cardId, buyerDetails) {
            var apiRoute = "/code/merchantgroup/" + merchantGroup + "/program/" + program + "/redeem/complete";
            return $http.post(prefixUrl(apiRoute), {
                code: code,
                sessionId: sessionId,
                externalId: externalId,
                cardId: cardId,
                buyerDetails: buyerDetails
            })
        }

        function redeemCancel(code, program, merchantGroup, sessionId) {
            var apiRoute = "/code/merchantgroup/" + merchantGroup + "/program/" + program + "/redeem/cancel";
            return $http.post(prefixUrl(apiRoute), {
                code: code,
                sessionId: sessionId
            })
        }
        //#endregion

        function getProgramHvtLimits(program) {
            var apiRoute = "/limits/program/" + program + "/hvtLimits";
            return $http.get(prefixUrl(apiRoute));
        }

        function getActiveMerchantGroupPrograms(merchantGroup) {
            var apiRoute = `/merchantgroup/${merchantGroup}/programs/active`;
            return $http.get(prefixUrl(apiRoute));
        }

        function submitHvtRequest(programUniqueTag, purchaserInformation, companyInformation, useOfCards, transactionInformation) {
            var apiRoute = `/program/${programUniqueTag}/hvt-request`;
            return $http.post(prefixUrl(apiRoute), {
                purchaserInformation: purchaserInformation,
                companyInformation: companyInformation,
                useOfCards: useOfCards,
                transactionInformation: transactionInformation
           });
        }

        function getRiskAssessmentLevels() {
            var apiRoute = "/kyb/risk-assessment-levels";
            return $http.get(prefixUrl(apiRoute));
        }

        function getCardFrontOrders(
        merchantGroupUniqueTag, 
        pageNumber, 
        pageSize, 
        orderStartDate, 
        orderEndDate, 
        statusChangeStartDate, 
        statusChangeEndDate,
        customerOrderReference,
        orderStatus,
        orderAmountQualifier,
        orderAmount,
        organizationName,
        purchaseOrderNumber,
        orderPlacedBy,
        sortAscending,
        sortColumn) {
            var apiRoute = "/merchantgroup/" + merchantGroupUniqueTag + "/card-front-orders?";
            apiRoute += `pageNumber=${pageNumber}&pageSize=${pageSize}`;
            apiRoute += orderStartDate ? `&orderStartDate=${orderStartDate}` : "";
            apiRoute += orderEndDate ? `&orderEndDate=${orderEndDate}` : "";
            apiRoute += statusChangeStartDate ? `&statusChangeStartDate=${statusChangeStartDate}` : "";
            apiRoute += statusChangeEndDate ? `&statusChangeEndDate=${statusChangeEndDate}` : "";
            apiRoute += customerOrderReference ? `&customerOrderReference=${customerOrderReference}` : "";
            apiRoute += orderStatus ? `&orderStatus=${orderStatus}` : "";
            apiRoute += orderAmountQualifier ? `&orderAmountQualifier=${orderAmountQualifier}` : "";
            apiRoute += orderAmount ? `&orderAmount=${orderAmount}` : "";
            apiRoute += organizationName ? `&organizationName=${organizationName}` : "";
            apiRoute += purchaseOrderNumber ? `&purchaseOrderNumber=${purchaseOrderNumber}` : "";
            apiRoute += orderPlacedBy ? `&orderPlacedBy=${orderPlacedBy}` : "";
            apiRoute += `&sortAscending=${sortAscending}`;
            apiRoute += `&sortColumn=${sortColumn}`;
            return $http.get(prefixUrl(apiRoute));
        }

        function getCardFrontOrderCardDetails(
        pageNumber,
        pageSize,
        customerOrderReference) {
            var apiRoute = `/card-front-order/${customerOrderReference}/cardholder/details?`;
            apiRoute += `pageNumber=${pageNumber}&pageSize=${pageSize}`;

            return $http.get(prefixUrl(apiRoute));
        }

        function searchCardFrontOrderCardDetails(
        pageNumber,
        pageSize,
        customerOrderReference,
        searchText) {
            var apiRoute = `/card-front-order/${customerOrderReference}/cardholder/details/search?`;
            apiRoute += `pageNumber=${pageNumber}&pageSize=${pageSize}`;
            apiRoute += searchText ? `&searchText=${searchText}` : "";

            return $http.get(prefixUrl(apiRoute));
        }

        function getCardFrontOrderDetails(
            customerOrderReference
        ) {
            var apiRoute = `/card-front-order/${customerOrderReference}/details`;

            return $http.get(prefixUrl(apiRoute));
        }

        function saveCardFrontOrderDetails(orderDetails)
        {
            var apiRoute = `/card-front-order/${orderDetails.customerOrderReference}/details`;

            return $http.put(prefixUrl(apiRoute), {
                orderId: orderDetails.orderId,
                customerOrderReference: orderDetails.customerOrderReference,
                siteId: orderDetails.siteId,
                siteVersionId: orderDetails.siteVersionId,
                programId: orderDetails.programId,
                merchantGroupId: orderDetails.merchantGroupId,
                perCardFee: orderDetails.perCardFee ?? 0.00,
                shippingFee: orderDetails.shippingFee ?? 0.00,
                transactionFeeAmount: orderDetails.transactionFeeAmount,
                purchaseOrderNumber: orderDetails.purchaseOrderNumber,
                orderAmount: orderDetails.orderAmount,
                numberOfCards: orderDetails.numberOfCards,
                billingInformation: {
                    id: orderDetails.billingInformation.id,
                    orderType: orderDetails.orderType,
                    firstName: orderDetails.billingInformation.firstName,
                    lastName: orderDetails.billingInformation.lastName,
                    address1: orderDetails.billingInformation.address1,
                    address2: orderDetails.billingInformation.address2,
                    city: orderDetails.billingInformation.city,
                    stateOrProvince: orderDetails.billingInformation.country.regionType === REGION_TYPES.STATE ? orderDetails.billingInformation.region.regionKey : orderDetails.billingInformation.region.regionName,
                    zipOrPostalCode: orderDetails.billingInformation.zipOrPostalCode,
                    country: orderDetails.billingInformation.country.countryName,
                    regionType: orderDetails.billingInformation.country.regionType,
                    regionKey: orderDetails.billingInformation.region.regionKey
                },
                shippingInformation: {
                    id: orderDetails.shippingInformation.id,
                    orderType: orderDetails.orderType,
                    firstName: orderDetails.shippingInformation.firstName,
                    lastName: orderDetails.shippingInformation.lastName,
                    address1: orderDetails.shippingInformation.address1,
                    address2: orderDetails.shippingInformation.address2,
                    city: orderDetails.shippingInformation.city,
                    stateOrProvince: orderDetails.shippingInformation.country.regionType === REGION_TYPES.STATE ? orderDetails.shippingInformation.region.regionKey : orderDetails.shippingInformation.region.regionName,
                    zipOrPostalCode: orderDetails.shippingInformation.zipOrPostalCode,
                    country: orderDetails.shippingInformation.country.countryName,
                    regionType: orderDetails.shippingInformation.country.regionType,
                    regionKey: orderDetails.shippingInformation.region.regionKey
                }
            });
        }

        function saveCardFrontCardDetails(cardDetails) {
            var apiRoute = `/card-front-order/cardholder/details`;

            return $http.put(prefixUrl(apiRoute), {
                orderId: cardDetails.orderId,
                orderItemId: cardDetails.orderItemId,
                shippingCostTotal: cardDetails.shippingCostTotal,
                siteId: cardDetails.siteId,
                siteVersionId: cardDetails.siteVersionId,
                programId: cardDetails.programId,
                programUniqueTag: cardDetails.programUniqueTag,
                merchantGroupId: cardDetails.merchantGroupId,
                orderItemCardAmountId: cardDetails.orderItemCardAmountId,
                cardAmount: cardDetails.cardAmount,
                cardholderPhone: cardDetails.phone,
                cardholderEmail: cardDetails.email,
                code: cardDetails.code,
                personalMessage: cardDetails.personalMessage,
                location: cardDetails.location,
                department: cardDetails.department,
                cardholderAddressInformation: {
                    id: cardDetails.orderItemCardAmountCardholderId,
                    orderType: cardDetails.orderType,
                    firstName: cardDetails.firstName,
                    lastName: cardDetails.lastName,
                    address1: cardDetails.address1,
                    address2: cardDetails.address2,
                    city: cardDetails.city,
                    stateOrProvince: cardDetails.country.regionType === REGION_TYPES.STATE ? cardDetails.region.regionKey : cardDetails.region.regionName,
                    zipOrPostalCode: cardDetails.zipOrPostalCode,
                    country: cardDetails.country.countryName,
                    regionType: cardDetails.country.regionType,
                    regionKey: cardDetails.region.regionKey,
                    isBulk: cardDetails.isBulk
                }
            });
        }

        function deleteCardFrontCardholder(orderItemId, orderItemCardAmountCardholderId, cardAmount) {
            var apiRoute = `/card-front-order/order-item/${orderItemId}/cardholder/${orderItemCardAmountCardholderId}?`;
            apiRoute += `cardAmount=${cardAmount}`;

            return $http.delete(prefixUrl(apiRoute));
        }

        function insertCardFrontCardholder(orderItemId, cardAmount, cardholder) {
            var apiRoute = `/card-front-order/order-item/${orderItemId}/cardholder`;

            return $http.post(prefixUrl(apiRoute), {
                cardAmount: cardAmount,
                cardholder: {
                    orderType: cardholder.orderType,
                    firstName: cardholder.firstName,
                    lastName: cardholder.lastName,
                    email: cardholder.email,
                    address1: cardholder.address1,
                    address2: cardholder.address2,
                    city: cardholder.city,
                    stateOrProvince: cardholder.region.regionKey,
                    zipOrPostalCode: cardholder.zipOrPostalCode,
                    country: cardholder.country.countryName,
                    phone: cardholder.phone,
                    code: cardholder.code,
                    personalMessage: cardholder.personalMessage,
                    location: cardholder.location,
                    department: cardholder.department,
                    isBulk: cardholder.isBulk
                }
            })
        }

        function getCardFrontOrderInvoice(orderId) {
            return $http.get(prefixUrl(`/card-front-order/${orderId}/invoice`), {
                responseType: "arraybuffer"
            });
        }
    }
}());