import humanizeDuration from 'humanize-duration';

(function () {
    "use strict";

    angular.module("cardspotWeb.areas.lookupCard")
        .controller("LookupCard", LookupCard);

    LookupCard.$inject = ["$scope", "$q", "receipts", "$translate", "$timeout", "$log", "notify", "cardService", "authService", "CLAIMS", "SF_EVENTS", "lookupValues", "navigate", "transactionService", "$routeParams", "appConfig", "$uibModal", "NAV_URLS", "moment"];

    function LookupCard($scope, $q, receipts, $translate, $timeout, $log, notify, cardService, authService, CLAIMS, SF_EVENTS, lookupValues, navigate, transactionService, $routeParams, appConfig, $uibModal, NAV_URLS, moment) {
        var model = this;
        model.cardsRetrieved = cardService.cardsRetrieved;
        model.selectedCard = null;
        model.lookupCardByPrintText = lookupCardByPrintText;
        model.selectCard = selectCard;
        model.cancelLookup = cancelLookup;
        model.clearAll = clearAll;
        model.card = {
            printText: null
        };
        model.search = {
            filterText: undefined
        };
        model.statusChange = {
            note: null,
            reason: null
        };
        model.amount = 0;
        model.sort = sort;
        model.sortKey = "transactionId";
        model.reverse = true;
        $scope.pageSize = 10;

        model.togglePanel = togglePanel;
        model.cardSummaryExpanded = true;
        model.salesDataExpanded = true;
        model.showReloadPopover = false;
        model.canViewActivationDetails = canViewActivationDetails;
        model.canTransferCard = canTransferCard;
        model.canReloadCard = canReloadCard;
        model.getStatusChangeButtonTitle = getStatusChangeButtonTitle;
        model.getStatusChangeButtonIcon = getStatusChangeButtonIcon;
        model.getStatusChangeTitle = getStatusChangeTitle;
        model.canChangeCardStatus = canChangeCardStatus;
        model.changeCardStatus = changeCardStatus;
        model.isLockable = isLockable;
        model.isUnlockable = isUnlockable;
        model.toggleCardStatusChange = toggleCardStatusChange;
        model.toViewActivationDetails = toViewActivationDetails;
        model.toTransferCard = toTransferCard;
        model.refreshCard = refreshCard;
        model.removeCard = removeCard;
        model.showReloadPopoverTrigger = showReloadPopoverTrigger;
        model.reloadCardModalPopupTrigger = reloadCardModalPopupTrigger;
        model.hideReloadPopover = hideReloadPopover;
        model.supportsSearchByClientTrackingId = appConfig.selectedMerchantGroup().supportsSearchByClientTrackingId;
        model.isSearchByCardNumber = isSearchByCardNumber;
        model.isSearchByClientTrackingId = isSearchByClientTrackingId;
        model.lookupBySearchType = lookupBySearchType;
        model.setSearchType = setSearchType;
        model.clearClientTrackingCards = clearClientTrackingCards;
        model.canViewRestrictedCardNumbers = authService.canViewRestrictedCardNumbers();
        model.maskPrintText = cardService.maskPrintText;
        model.selectTab = selectTab;
        model.lockCardModalPopupTrigger = lockCardModalPopupTrigger;
        model.resetChangeStatusForm = resetChangeStatusForm;
        model.resetReloadAmountForm = resetReloadAmountForm;
        model.displayPrintText = displayPrintText;
        model.canReprint = canReprint;
        model.getReceiptHtml = getReceiptHtml;
        model.supportSearchByCarrierNumber = appConfig.selectedMerchantGroup().supportSearchByCarrierNumber;
        model.isSearchByCarrierNumber = isSearchByCarrierNumber;
        model.clearCarrierNumberCards = clearCarrierNumberCards;
        model.NAV_URLS = NAV_URLS;        
        model.showExtendAccountExpirationModal = showExtendAccountExpirationModal;
        model.confirmExtendAccountExpiration = confirmExtendAccountExpiration;
        model.getMaxAccountExpirationDate = getMaxAccountExpirationDate;
        model.canExtendAccountExpirationDate = false;    
        model.expirationDateChanged = expirationDateChanged;
        model.humanizedDifference = null;
        
        model.selectedExtendAccountExpirationReason = null;   
        model.searchButtonStyle = searchButtonStyle;             

        model.SEARCH_TYPE = {
            "CARD_NUMBER": "cardNumber",
            "CLIENT_TRACKING_ID": "clientTrackingId",
            "CARRIER_NUMBER": "carrierNumber"
        };
        var TAB_NAMES = {
            CARD_SUMMARY: "card summary",
            SALES_DATA: "sales data",
            TRANSACTIONS: "transactions"
        };

        $scope.TAB_NAMES = TAB_NAMES;
        var activeTab = TAB_NAMES.CARD_SUMMARY;

        $scope.$on(SF_EVENTS.CARDSWIPE_SUCCESS, handleCardSwipeSuccess);

        init();

        function init() {
            if ($routeParams.printText) {
                doLookupCard($routeParams.printText);
            } else if (cardService.cardJustRetrieved()) {
                selectCard(cardService.cardJustRetrieved());
            }
            model.clientTrackingCards = cardService.clientTrackingCardsRetrieved();
            model.carrierNumberCards = cardService.carrierNumberCardsRetrieved();
        }

        function selectTab(tabName) {
            activeTab = tabName;
        }

        function resetLookup() {
            model.card.printText = null;
            model.search.filterText = undefined;
            notify.dismissErrorAlert();
            if ($scope.lookupCardForm) {
                $scope.lookupCardForm.$setPristine();
            }
            resetClientTrackingSearch();
        }

        function resetClientTrackingSearch() {
            notify.dismissErrorAlert();
            model.search.searchText = null;
            if ($scope.lookupCardClientTrackingForm) {
                $scope.lookupCardClientTrackingForm.$setPristine();
            }
        }

        function resetChangeStatusForm() {
            model.statusChange = {
                note: null,
                reason: null
            };
        }

        function resetReloadAmountForm() {
            model.amount = 0;
        }

        function setHaveCards() {
            model.haveCards = cardService.cardsRetrieved.length > 0;
        }

        function doLookupCard(printText, primaryAccountNumber, trackData) {
            notify.dismissErrorAlert();
            printText = cleanCardNumberInput(printText);
            lookupCard(printText, primaryAccountNumber, trackData);
        }

        function lookupCard(printText, primaryAccountNumber, trackData) {
            var lookupFunction = null;

            if (printText) {
                // need to determine if this should be treated as printText or externalId
                // if startsWith alpha, then treat as externalid search
                if (/^[A-Za-z]/.test(printText)) {
                    lookupFunction = cardService.lookupByExternalId(printText);
                } else {
                    lookupFunction = cardService.lookupByPrintText(printText);
                }
            }
            else if (trackData && (trackData.track1Data || trackData.track2Data)) {
                lookupFunction = cardService.lookupBySwipe(trackData.track1Data, trackData.track2Data);
            }
            else {
                notify.showError("LOOKUP_CARD.FORM.INVALID_PRINT_TEXT_OR_SWIPE");
                return;
            }

            notify.forHttp(lookupFunction, { startTranslationId: "LOOKUP_CARD.FORM.PROGRESS_TEXT" })
                .then(function () {
                    selectCard(cardService.cardJustRetrieved());
                });
        }

        function lookupCardByPrintText() {
            notify.forFormSubmission($scope.lookupCardForm, doLookupCard(model.card.printText));
        }

        function lookupBySearchType() {
            if (cardSearchType === model.SEARCH_TYPE.CARD_NUMBER) {
                setSearchType(model.SEARCH_TYPE.CARD_NUMBER);
                notify.forFormSubmission($scope.lookupCardClientTrackingForm, doLookupCard(model.search.searchText));
            } else if (cardSearchType === model.SEARCH_TYPE.CARRIER_NUMBER) {
                notify.forFormSubmission($scope.lookupCardClientTrackingForm, lookupByCarrierNumber(model.search.searchText));
            } else {
                notify.forFormSubmission($scope.lookupCardClientTrackingForm, lookupByClientTrackingId(model.search.searchText));
            }
        }

        function lookupByClientTrackingId(clientTrackingId) {
            clearClientTrackingCards();
            notify.forHttp(cardService.lookupByClientTrackingId(clientTrackingId), { startTranslationId: "LOOKUP_CARD.FORM.PROGRESS_TEXT" })
                .then(data => {
                    processClientTrackingIdSearch(data);
                })
                .catch(() => notify.showError("LOOKUP_CARD.FORM.NO_CLIENT_TRACKING_ID_CARD_FOUND"));
        }

        function lookupByCarrierNumber(carrierNumber) {
            clearCarrierNumberCards();
            notify.forHttp(cardService.lookupByCarrierNumber(carrierNumber), { startTranslationId: "LOOKUP_CARD.FORM.PROGRESS_TEXT" })
                .then(data => {
                    processCarrierNumberSearch(data);
                })
                .catch(() => notify.showError("LOOKUP_CARD.FORM.NO_CARRIER_NUMBER_CARD_FOUND"));
        }

        function processClientTrackingIdSearch(data) {
            resetClientTrackingSearch();
            if (data.cards && data.cards.length > 0) {
                model.clientTrackingCards = cardService.clientTrackingCardsRetrieved();
            } else {
                selectCard(cardService.cardJustRetrieved());
            }
        }

        function processCarrierNumberSearch(data) {
            resetClientTrackingSearch();
            if (data.cards && data.cards.length > 0) {
                model.carrierNumberCards = cardService.carrierNumberCardsRetrieved();
            } else {
                selectCard(cardService.cardJustRetrieved());
            }
        }

        function selectCard(card) {
            model.selectedCard = card;
            model.canExtendAccountExpirationDate = canExtendAccountExpiration();            
            resetLookup();
            setHaveCards();
        }

        function cancelLookup() {
            resetLookup();
        }

        function clearAll() {
            cardService.clearAll();
            model.haveCards = false;
            resetLookup();
        }

        function handleCardSwipeSuccess(event, swipe) {
            doLookupCard(undefined, swipe.primaryAccountNumber, { track1Data: swipe.track1Data, track2Data: swipe.track2Data });
        }

        function sort(columnKey) {
            model.sortKey = columnKey;
            model.reverse = !model.reverse;
        }

        function getStatusChangeButtonTitle() {
            return model.selectedCard && model.selectedCard.isLockable ? "LOOKUP_CARD.CARD_PROPERTIES.BUTTON_TEXT.LOCK" : "LOOKUP_CARD.CARD_PROPERTIES.BUTTON_TEXT.UNLOCK";
        }

        function getStatusChangeButtonIcon() {
            return model.selectCard && model.selectedCard.isLockable ? "fa-lock" : "fa-unlock";
        }

        function getStatusChangeTitle() {
            return model.selectedCard && model.selectedCard.isLockable ? "LOOKUP_CARD.CARD_PROPERTIES.LOCK_CARD_TITLE" : "LOOKUP_CARD.CARD_PROPERTIES.UNLOCK_CARD_TITLE";
        }

        function togglePanel() {
            model.cardSummaryExpanded = !model.cardSummaryExpanded;
            model.salesDataExpanded = !model.salesDataExpanded;
        }

        function canTransferCard() {
            return model.selectedCard && model.selectedCard.isTransferable && authService.canTransferCard();
        }

        function canReloadCard() {
            return model.selectedCard 
            && (model.selectedCard.status == 'Active' || model.selectedCard.status =='Activation Lock') 
            && authService.canReloadCard();
        }

        function canViewActivationDetails() {
            return model.selectedCard && model.selectedCard.activationTransactionIsViewable && authService.canViewActivationDetails();
        }

        function toggleCardStatusChange() {
            model.showStatusChangeForm = !model.showStatusChangeForm;
            if (model.showStatusChangeForm && isLockable()) {
                getlockCardReasons();
            } else {
                resetChangeStatusForm();
            }
        }

        function isLockable() {
            return model.selectedCard && model.selectedCard.isLockable && authService.canLockCard();
        }

        function isUnlockable() {
            return model.selectedCard && model.selectedCard.isUnlockable && authService.canUnlockCard();
        }

        function getlockCardReasons() {
            notify.forHttp(lookupValues.getLockCardReasons(), { startTranslationId: "LOOKUP_VALUES.GET_LOCK_CARD_REASONS_PROGRESS_TEXT" })
                .then(function (data) {
                    model.lockCardReasons = data;
                });
        }

        function changeCardStatus(statusChangeForm) {
            if (isLockable()) {
                lockCard(statusChangeForm);
            } else {
                unlockCard(statusChangeForm);
            }
        }

        function lockCard(form) {
            notify.forHttp(cardService.lockCard(model.selectedCard.cardNumber, model.statusChange.reason, model.statusChange.note),
                { startTranslationId: "LOOKUP_CARD.FORM.LOCK_CARD_PROGRESS" })
                .then(function () {
                    refreshCard(model.selectedCard);
                });
        }

        function unlockCard(form) {
            notify.forHttp(cardService.unlockCard(model.selectedCard.cardNumber, model.statusChange.note),
                { startTranslationId: "LOOKUP_CARD.FORM.UNLOCK_CARD_PROGRESS" })
                .then(function () {
                    refreshCard(model.selectedCard);
                });
        }

        function refreshCard(card) {

            notify.forHttp(cardService.lookupByPrintText(card.printText), { startTranslationId: "LOOKUP_CARD.FORM.PROGRESS_TEXT" })
                .then(function () {
                    model.selectedCard = cardService.findCard(model.selectedCard.printText);
                    model.canExtendAccountExpirationDate = canExtendAccountExpiration();                     
                });
            resetChangeStatusForm();
            model.showStatusChangeForm = false;
        }

        function refreshCardPromise(card) {
            return new Promise((resolve, reject) => {
                try {
                        refreshCard(card);
                        resolve();
                } catch (error) {
                    reject(error);
                }
            });
        }

        function canChangeCardStatus() {
            return (isLockable() || isUnlockable());
        }

        function toViewActivationDetails() {
            navigate.toViewActivationDetails(model.selectedCard.activationTransactionId);
        }

        function toTransferCard() {
            cardService.setTransferCard(model.selectedCard);
            navigate.toTransferCard();
        }

        function reloadCardModalPopup() {
            hideReloadPopover();
            resetReloadAmountForm();
            var reloadCardModalInstance = $uibModal.open({
                templateUrl: "lookup-card/modal/reload-card-dialog.html",
                backdrop: "static",
                keyboard: false,
                controller: "ConfirmModal",
                scope: $scope
            });

            reloadCardModalInstance.result.then(function (amount) {
                if (amount) {
                    doReloadCard();
                }
            });
        }

        function doReloadCard() {
            reloadCard();
        }

        function reloadCardModalPopupTrigger() {
            reloadCardModalPopup();
        }

        function showReloadPopoverTrigger() {
            model.showReloadPopover = true;
        }

        function hideReloadPopover() {
            model.showReloadPopover = false;
        }

        function reloadCard() {            

            var merchantGroup = appConfig.selectedMerchantGroup();

            var reloadRequest = {
                "Amount": model.amount,
                "MerchantGroup": merchantGroup.uniqueTag,
                "Location" : {
                    "Name": merchantGroup.name,
                    "State" : merchantGroup.location.state,
                    "Country" : merchantGroup.location.country,
                    "Province" : merchantGroup.location.province
                }
            }

            var reloadFunction = cardService.reloadCard(model.selectedCard.externalId, reloadRequest);

            notify.forHttp(reloadFunction, { startTranslationId: "LOOKUP_CARD.FORM.RELOADING_TEXT" })
            .then(function () {                              
                return refreshCardPromise(model.selectedCard);
            })
            .catch((e) => { console.log(e); notify.showError("LOOKUP_CARD.FORM.RELOADING_ERROR")});
        }

        function removeCard(card) {
            cardService.removeCard(card.printText);
            //waiting to digest cycle ends before navigating
            $timeout(function(){
                navigate.toLookupCard();
            });
        }

        $scope.disableEnter = function(event) {
            if (event.which === 13) {  // Check if Enter key is pressed
                event.preventDefault();
            }
        }

        var cardSearchType = appConfig.selectedMerchantGroup().supportsSearchByClientTrackingId ? model.SEARCH_TYPE.CLIENT_TRACKING_ID : appConfig.selectedMerchantGroup().supportSearchByCarrierNumber ? model.SEARCH_TYPE.CARRIER_NUMBER : model.SEARCH_TYPE.CARD_NUMBER;
        function setSearchType(searchType) {
            cardSearchType = searchType;
            if (cardSearchType === model.SEARCH_TYPE.CARD_NUMBER) {
                clearClientTrackingCards();
            }
        }

        function isSearchByCardNumber() {
            return cardSearchType === model.SEARCH_TYPE.CARD_NUMBER;
        }

        function isSearchByClientTrackingId() {
            return cardSearchType === model.SEARCH_TYPE.CLIENT_TRACKING_ID;
        }

        function isSearchByCarrierNumber() {
            return cardSearchType === model.SEARCH_TYPE.CARRIER_NUMBER;
        }

        function clearClientTrackingCards() {
            model.clientTrackingCards = null;
            cardService.resetClientTrackingCards();
        }

        function clearCarrierNumberCards() {
            model.carrierNumberCards = null;
            cardService.resetCarrierNumberCards();
        }

        function cleanCardNumberInput(input) {
            return input ? input.replace(/\s/g, "") : input;
        }

        function displayPrintText(printText) {
            return model.canViewRestrictedCardNumbers ? printText : cardService.maskPrintText(printText);
        }

        function lockCardModalPopup() {
            resetChangeStatusForm();
            getlockCardReasons();
            var lockCardModalInstance = $uibModal.open({
                templateUrl: "lookup-card/modal/lock-card-dialog.html",
                backdrop: "static",
                keyboard: false,
                controller: "ConfirmModal",
                scope: $scope
            });

            lockCardModalInstance.result.then(function (status) {
                if (status) {
                    changeCardStatus();
                }
            });
        }

        function lockCardModalPopupTrigger() {
            lockCardModalPopup();
        }

        $scope.tabClass = function (tabName) {
            var cssClass = "";
            if (tabName === activeTab) {
                cssClass = "active";
            }
            return cssClass;
        }

        $scope.tabVisible = function (tabName) {
            return tabName === activeTab;
        }

        function getReceiptHtml(transactionId) {
            let deferred = $q.defer(),
                isReprint = true;
            receipts.getReceipt(transactionId, $translate.currentLanguage().key, isReprint).then(function (receipt) {
                deferred.resolve(receipt.receiptHtml);
            }, function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        function canReprint(item) {
            return !authService.isReprintDisabled() && (item.activity === 'New Card Activation' || item.activity === 'Transfer');
        }

        function showExtendAccountExpirationModal() {
            resetExtendAccountExpirationForm(); 
            getExpirationExtensionReasons(); 
            setExtendFromDisplayName();
            cardService.calculateNewAccountExpiration(model.selectedCard.cardNumber)
                .then(function (response) {                    
                    model.newAccountExpirationDate = response.data;
                    expirationDateChanged(); 
                    var extendAccountExpirationModalInstance = $uibModal.open({
                        templateUrl: "lookup-card/modal/extend-account-expiration-dialog.html",
                        backdrop: "static",
                        keyboard: false,
                        controller: "ConfirmModal",
                        scope: $scope
                    });
        
                    extendAccountExpirationModalInstance.result.then(function (status) {
                        if (status) {                   
                            confirmExtendAccountExpiration(model.newAccountExpirationDate,
                                model.selectedExtendAccountExpirationReason,
                                extendAccountExpirationModalInstance);                    
                        }
                    });                           
            })
            .catch(() => notify.showError("LOOKUP_CARD.FORM.ACCOUNT_EXPIRATION_CALCULATION_ERROR"));                   
        }

        function confirmExtendAccountExpiration(newDate, extensionReason, extendAccountExpirationModalInstance) {
            var confirmExtendAccountExpirationInstance = $uibModal.open({
				templateUrl: "lookup-card/modal/confirm-extend-account-expiration.html",
				backdrop: "static",
				keyboard: false,				
				controller: "ConfirmModal",
				scope: $scope
			})

			confirmExtendAccountExpirationInstance.result.then(function (confirmed) {
                if (confirmed){
                    extendAccountExpiration(newDate, extensionReason);                    
                }
				extendAccountExpirationModalInstance.close();
			});
		}		

        function extendAccountExpiration(newExpirationDate, extensionReason) {                  
            notify.forHttp(cardService.extendAccountExpiration(model.selectedCard.cardNumber, newExpirationDate, extensionReason),
                { startTranslationId: "LOOKUP_CARD.FORM.EXTEND_ACCOUNT_EXPIRATION_PROGRESS" })
                .then(function () {
                    notify.success("LOOKUP_CARD.CONFIRMATION.ACCOUNT_EXPIRATION_EXTENDED", undefined, 1000);                    
                    refreshCard(model.selectedCard);                     
                });
        }

        function resetExtendAccountExpirationForm(){ 
            model.selectedExtendAccountExpirationReason = null;           
            model.extendAccountExpirationPopup = {
                opened: false,
                minDate: new Date(),
                maxDate: getMaxAccountExpirationDate(),
                newAccountExpirationDate: null
            };
        }

        function canExtendAccountExpiration() {     
            if (!authService.canExtendAccountExpiration()) return false;
            if (!model.selectedCard || !model.selectedCard.isAccountExpired ) return false;
            if (!model.selectedCard.accountExpConfig || !model.selectedCard.accountExpConfig.allowExtension) return false;
            if ( model.selectedCard.accountExpConfig.maxExtensionsAllowed  && 
                (model.selectedCard.accountExpirationExtensionCount >= model.selectedCard.accountExpConfig.maxExtensionsAllowed)) return false;
            
            let maxAccountExpDate = getMaxAccountExpirationDate(); 

            const isEligiblePerPlastic = maxAccountExpDate ? 
                moment(maxAccountExpDate).isAfter(moment()) :
                true;

            return isAccountExpirationConfigurationEligible() && isEligiblePerPlastic;
        }

        function isAccountExpirationConfigurationEligible() {
            if (model.selectedCard.accountExpConfig.eligibilityPeriodDays == null) return true;
            
            const currentAccountExpireDate = moment(model.selectedCard.accountExpiresDate).toDate();
            const maxEligibleDate = moment(currentAccountExpireDate).add(model.selectedCard.accountExpConfig.eligibilityPeriodDays, 'days').toDate()

            return maxEligibleDate ?
                moment(maxEligibleDate).isAfter(moment()) :
                true;
        } 

        function getMaxAccountExpirationDate() {
            if (!model.selectedCard || !model.selectedCard.expiresDate) return null;

            return moment(model.selectedCard.expiresDate).toDate();            
        }        		               
        
        function expirationDateChanged(){
            model.humanizedDifference = null;
            const currentLanguage = $scope.currentLanguage().language;
            const humanizer = humanizeDuration.humanizer({
                // mapping norwegian to 'no' to use humanize translation;
                // other languages map 1:1
                language: currentLanguage == 'nb' ? 'no' : currentLanguage,
                units: ['y', 'mo', 'd'],
                round: true,
                unitMeasures: {
                    y: 31536000000,
                    mo: 2592000000,         
                    d: 86400000
                }                
              });            
            const duration = Number(model.selectedCard.accountExpConfig.extensionPeriodDays) * 24 * 60 * 60 * 1000;
            
            model.humanizedDifference = humanizer(duration);                     
        };

        function getExpirationExtensionReasons() {            
            notify.forHttp(lookupValues.getAccountExpirationExtensionReasons(), { startTranslationId: "LOOKUP_VALUES.GET_EXTENSION_REASONS_PROGRESS_TEXT" })
                .then( function(data) {
                    data.forEach(reason => {
                        reason.formattedName = reason.name.replace(/([A-Z])/g, ' $1').trim();  							
                    })
                    model.extendAccountExpirationReasons = data;                               
                });
        }
        
        function setExtendFromDisplayName() {            
            model.extendFromDisplayName = model.selectedCard.accountExpConfig.extensionPeriodStartType.replace(/([A-Z])/g, ' $1').toLowerCase().trim();
        } 

        function searchButtonStyle() {
            if (model.haveCards && model.cardsRetrieved.length > 1) {
                return {
                    "border-radius": "0"
                }
            }
            return {
                "border-bottom-right-radius": "7px",
                "border-top-right-radius": "7px"
            }
        }
    }
}());